import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "../../common/Image";
import styled from "styled-components";
import { Breakpoints, Colors, Spacing } from "../../../styles/styleConstants";
import { HEADER_LEVEL } from "../../../utils/commonConst";
import HeaderText from "../../common/HeaderText";
import HelperText from "../../common/HelperText";
import CtaButton from "../../common/CtaButton";
import { getAppUrlByModule, navigateTo } from "../../../utils/commonUtil";

export default function PeachHero(props) {
  const { signupCode } = props ?? {};
  const [paramString, setParamString] = useState("");
  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);

  return (
    <HeroWrapper>
      <Container>
        <Row>
          <Col md={6} lg={5}>
            <UprisePeachLogoWrapper>
              <Image
                src="affiliate/uprise-peach-logo.png"
                alt="Uprise + Peach"
                className="UprisePeachImage"
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </UprisePeachLogoWrapper>
            <HeaderText level={HEADER_LEVEL.LEVEL_1}>
              Never worry about money again.
            </HeaderText>
            <div className="my-4 textBig">
              Add an average $1.5m in net worth back in your pocket with expert
              advice from our team of Certified Financial Planners.
            </div>
            {/* <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Fully personalized</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Reviewed by a (human) expert</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>100% free</ValuePropText>
            </ValuePropTextWrapper> */}
            <CtaWrapper>
              {/* <WaitlistModalCta signupCode={signupCode} /> */}
              <CtaButton
                onClick={(evt) => {
                  // _onSignup();
                  navigateTo(getAppUrlByModule());
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="primary"
              >
                Get started
              </CtaButton>
            </CtaWrapper>
          </Col>
          <Col md={6} lg={7}>
            <Image className="HeroImage" src="home/illustration_1.png" />
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  margin-top: 86px;
  .HeroImage {
    width: 100%;
    margin-top: ${Spacing.xxl};
  }
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 40px;
  }
`;

const UprisePeachLogoWrapper = styled.div`
  margin: 0px 0px 32px 0px;
  .UprisePeachImage {
    width: 240px;
    height: 40px;
    @media (max-width: ${Breakpoints.sm}) {
      height: 32px;
    }
  }
`;

const NavigateToCostFaq = styled.a`
  position: relative;
  font-weight: normal;
  color: ${Colors.almostBlack};
  background: linear-gradient(
      90deg,
      rgba(49, 122, 249, 1) 0%,
      rgba(5, 232, 225, 1) 100%
    )
    repeat-x;
  background-size: 330px 6px;
  background-position: 0px 0.99em;

  :hover {
    color: ${Colors.blue};
    // cursor: pointer;
    text-decoration: none;
  }
`;

const CtaWrapper = styled.div`
  margin-top: 24px;
`;

const ValuePropTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

const ValuePropIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const ValuePropText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;

  color: #212529;

  margin: 0px 16px;
`;
